'use client';
import React, { useEffect } from 'react';
import { IoLogoInstagram, IoLogoFacebook, IoLogoYoutube } from 'react-icons/io';
import { Metadata } from 'next';

export const metadata: Metadata = {
  title: 'Fehler aufgetreten',
};

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    import('../common/bugsnack').then((bugsnack) => {
      bugsnack.default.notify(error);
    });
  }, [error]);

  return (
    <>
      <h1 className="text-center m-5">Ohhhh, es ist ein Fehler aufgetreten</h1>
      <p className="m-5 text-center sm:mx-100">
        Mach dir keine Sorgen, du kannst mit einem Klick zurück zur{' '}
        <a href="/" title="Zur Startseite">
          Startseite
        </a>{' '}
        gelangen.
      </p>
      <p className="m-5 text-center sm:mx-100 dark:text-lighterGrey dark:pb-5">
        <button onClick={() => reset()}>Try again</button>
      </p>
      <div className="w-full flex items-center justify-center pb-6">
        <a
          href="https://www.instagram.com/promiflash/"
          target="_blank"
          rel="noreferrer"
          title="Unser Instagram"
        >
          <IoLogoInstagram size={55}></IoLogoInstagram>
        </a>
        <a
          href="https://www.facebook.com/Promiflash/"
          target="_blank"
          rel="noreferrer"
          title="Unser Facebook"
        >
          <IoLogoFacebook size={55}></IoLogoFacebook>
        </a>
        <a
          href="https://www.youtube.com/user/PromiFlash"
          target="_blank"
          rel="noreferrer"
          title="Unser Youtube"
        >
          <IoLogoYoutube size={55}></IoLogoYoutube>
        </a>
      </div>
      <div className="pt-[56.25%] w-full relative">
        <iframe
          className="absolute top-0"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/videoseries?list=UUFVY0654zQ6DVFAHspGiOwg"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}
